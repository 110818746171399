<template>
  <div class="bbr-challenge-new-workouts-days pb-12">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Challenge Details</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            :loading="this.form.$busy"
            @click="saveForLater"
            depressed
            text
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :loading="this.form.$busy"
            @click="saveAndNext"
            depressed
            text
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <challenge-details-from :form-data="form" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChallengeDetailsFrom from '../components/ChallengeDetailsForm'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Event from '@/services/eventBus'
import { mdiArrowRight } from '@mdi/js'
import Form from '@/utils/form'
import { pick, isNull } from 'lodash'

export default {
  name: 'ChallengeDetails',

  components: {
    ChallengeDetailsFrom,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
      },
      form: new Form({
        type: 2,
        id: this.$attrs.id,
        name: null,
        ends_at: null,
        starts_at: null,
        week_count: null,
        package_id: null,
        trainer_id: null,
        has_deload: null,
        description: null,
        welcome_message: null,
        access_starts_at: null,
        deload_week_count: null,
        package: {},
        has_extended_validity: false,
        is_pregnancy_safe: false,
        trainers: [],
        training_programs: [],
        workout_type: null,
        has_workout_days: false,
      }),
    }
  },

  computed: {
    ...mapGetters({
      isCompleted: 'challenges/isSelectedChallengeComplete',
      challenge: 'challenges/getSelectedChallenge',
    }),
  },

  created() {
    if (this.$attrs.id) {
      this.fillForm(this.challenge)
    }
  },

  methods: {
    ...mapActions({
      saveChallenge: 'challenges/saveChallenge',
    }),

    ...mapMutations({
      setSelectedChallenge: 'challenges/setSelectedChallenge',
    }),

    saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      const trainerIds = this.form.trainers.map((i) => {
        return i.id
      })

      const trainerProgramIds = this.form.training_programs.map((i) => {
        return i.id
      })

      this.saveChallenge(
        this.form.$data({
          is_pregnancy_safe: isNull(this.form.is_pregnancy_safe)
            ? false
            : this.form.is_pregnancy_safe,
          trainer_ids: trainerIds,
          training_program_ids: trainerProgramIds,
        })
      )
        .then(({ data }) => {
          Event.$emit('udpate-draft-challenge-list')

          this.form.$timeout(() => {
            this.form.$busy = false
            this.$router.push({ name: 'draft.challenges' })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    saveAndNext() {
      this.form.$busy = true
      this.form.$clearErrors()

      const trainerIds = this.form.trainers.map((i) => {
        return i.id
      })

      const trainerProgramIds = this.form.training_programs.map((i) => {
        return i.id
      })

      this.saveChallenge(
        this.form.$data({
          is_pregnancy_safe: isNull(this.form.is_pregnancy_safe)
            ? false
            : this.form.is_pregnancy_safe,
          trainer_ids: trainerIds,
          training_program_ids: trainerProgramIds,
        })
      )
        .then((data) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            this.setSelectedChallenge(data)

            let endpoint = this.form.has_workout_days
              ? 'workout-days'
              : 'rich-description'
            let name = !this.isCompleted
              ? `new.challenge.${endpoint}`
              : `challenge.${endpoint}`

            this.$router.push({
              name: name,
              params: { id: data.id },
            })
          })
        })
        .catch((err) => {
          this.form.$timeout(() => {
            if (err.response && err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    fillForm(challenge) {
      this.form = new Form(
        pick(challenge, [
          'id',
          'name',
          'type',
          'ends_at',
          'trainer',
          'package',
          'starts_at',
          'trainer_id',
          'package_id',
          'week_count',
          'has_deload',
          'has_extended_validity',
          'is_pregnancy_safe',
          'description',
          'package_name',
          'welcome_message',
          'access_starts_at',
          'deload_week_count',
          'training_programs',
          'workout_type',
          'has_workout_days',
          'trainers',
        ])
      )
    },
  },

  watch: {
    challenge() {
      if (this.$attrs.id) {
        this.fillForm(this.challenge)
      }
    },
  },
}
</script>
