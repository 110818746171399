<template>
  <div class="bbr-plans--search-plan-input">
    <v-autocomplete
      v-model="plan"
      v-bind="$attrs"
      item-text="name"
      item-value="id"
      :label="label"
      :items="plans"
      :readonly="readonly"
      :hide-details="!hasErrors"
      :search-input.sync="search"
      :error-messages="searchInputErrors"
      data-testid="plan-search-input"
      @change="selectedPlan"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-text="data.item.name" />
          <v-list-item-subtitle
            class="text-no-wrap"
            v-if="withDescription"
            style="width:20px"
          >
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import Plan from '@/models/Plan'

import { has, isEmpty, flatMap, debounce } from 'lodash'
import { mdiInformation } from '@mdi/js'

export default {
  name: 'SearchPlanInput',

  props: {
    value: {
      type: [Number, Object],
    },

    defaultValue: {
      type: [Object, Array],
    },

    label: {
      type: String,
      default: 'Search Plan',
    },

    errors: {
      type: [Object, Array],
    },

    withDescription: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      plans: [],
      plan: this.value,
      search: null,
      loading: false,
      icons: {
        info: mdiInformation,
      },
    }
  },

  computed: {
    searchInputErrors() {
      return Array.isArray(this.errors) && this.exercise
        ? this.errors
        : flatMap(this.errors)
    },

    hasErrors() {
      return !!this.searchInputErrors.length
    },
  },

  created() {
    this.setDefaultValue()
  },

  methods: {
    setDefaultValue() {
      if (isEmpty(this.defaultValue)) return

      if (Array.isArray(this.defaultValue)) {
        this.plans.push(...this.defaultValue)
      } else {
        this.plans.push(this.defaultValue)
      }
    },

    selectedPlan(plan) {
      if (plan) {
        this.$emit('input', plan)
      }

      if (!plan && has(this.$attrs, 'clearable')) {
        this.$emit('input', null)
      }
    },

    async searchPlans(self, search, page = 1) {
      if (self.loading) return

      const query = Plan.page(page).where('completed', 1)

      self.loading = true

      if (search) {
        query.where('search', search)
      }

      await query
        .params({ limit: 100 })
        .get()
        .then(({ data }) => {
          self.loading = false

          this.plans = data
        })
        .catch((err) => {
          self.loading = false

          return err
        })
    },
  },

  watch: {
    search(value) {
      if (value) {
        debounce(this.searchPlans, 600)(this, this.search)
      }
    },

    value(value) {
      this.plan = value
    },

    defaultValue(values) {
      this.setDefaultValue()
    },
  },
}
</script>
