<template>
  <v-row class="bbr-challenge--new-details-form">
    <v-col cols="12" sm="2">
      <v-checkbox
        v-model="form.has_deload"
        label="Has Deload"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.has_extended_validity"
        label="Has Extended Validity"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.is_pregnancy_safe"
        label="Is Pregnancy Safe"
        color="primary"
      />
    </v-col>

    <v-col cols="12" sm="3">
      <v-checkbox
        v-model="form.has_workout_days"
        label="Has Workout Days"
        color="primary"
      />
    </v-col>

    <v-spacer />

    <v-col cols="12" sm="4">
      <v-menu
        v-model="accessDateMenu"
        class="bbr-challenge--new-details-datepicker"
        transition="scale-transition"
        min-width="252px"
        :close-on-content-click="false"
        offset-y
        bottom
        right
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            :value="tzFormat(form.access_starts_at)"
            :append-icon="icons.calendar"
            @click:clear="form.access_starts_at = null"
            label="Access Date"
            readonly
            outlined
            flat
          />
        </template>
        <vc-date-picker
          ref="picker1"
          min="2020-01-01"
          v-model="form.access_starts_at"
          mode="dateTime"
          :model-config="dateConfig"
          timezone="Australia/Brisbane"
        />
      </v-menu>
    </v-col>

    <v-col cols="12" sm="8">
      <v-text-field
        :error-messages="form.$getError('name')"
        v-model="form.name"
        label="Challenge Name"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="4">
      <MultipleTrainerSearchInput
        v-model="form.trainers"
        label="Challenge Trainers"
        clearable
        outlined
        flat
        multiple
        :error-messages="form.$getError('trainer_ids')"
      />
    </v-col>

    <v-col cols="12" :sm="hasDeload ? 6 : 8">
      <search-plan-input
        v-model="form.package_id"
        label="Challenge Plan"
        :default-value="form.package"
        with-description
        clearable
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" :sm="hasDeload ? 3 : 4">
      <v-text-field
        :error-messages="form.$getError('week_count')"
        label="Week count"
        v-model="form.week_count"
        v-mask="'##'"
        @input="onDateSelect"
        outlined
        flat
      />
    </v-col>

    <v-col v-if="hasDeload" cols="12" sm="3">
      <v-text-field
        v-mask="'##'"
        v-model="form.deload_week_count"
        label="Deload Week count"
        :error-messages="form.$getError('deload_week_count')"
        outlined
        flat
      />
    </v-col>

    <v-col cols="12" sm="6">
      <v-menu
        v-model="startDateMenu"
        class="bbr-challenge--new-details-datepicker"
        transition="scale-transition"
        min-width="290px"
        :close-on-content-click="false"
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :error-messages="form.$getError('starts_at')"
            v-model="form.starts_at"
            v-on="on"
            label="Start Date"
            @input="onDateSelect"
            readonly
            outlined
            flat
          />
        </template>
        <v-date-picker
          v-model="form.starts_at"
          @input="startDateMenu = false"
          @change="onDateSelect"
          :allowed-dates="allowedStartDates"
          scrollable
          no-title
        />
      </v-menu>
    </v-col>

    <v-col cols="12" sm="6">
      <v-menu
        v-model="endDateMenu"
        :close-on-content-click="false"
        min-width="290px"
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :error-messages="form.$getError('ends_at')"
            v-model="form.ends_at"
            label="End Date"
            v-on="on"
            readonly
            outlined
            flat
          />
        </template>
        <v-date-picker
          v-model="form.ends_at"
          @input="endDateMenu = false"
          :min="form.starts_at"
          scrollable
          disabled
          readonly
          no-title
        />
      </v-menu>
    </v-col>
    <v-col cols="4">
      <v-select
        label="Workout Type"
        :error-messages="form.$getError('workout_type')"
        v-model="form.workout_type"
        :items="workoutTypes"
        item-text="text"
        item-value="id"
        outlined
        flat
      ></v-select>
    </v-col>

    <v-col cols="8">
      <TrainingProgramSearchInput
        label="Training Programs"
        :error-messages="form.$getError('training_program_ids')"
        v-model="form.training_programs"
        hide-selected
        small-chips
        multiple
        outlined
        flat
        :workout-type="form.workout_type"
        :disabled="!form.workout_type"
        :readonly="form.workout_type === 1"
      />
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-model="form.description"
        label="Challenge Description"
        auto-grow
        outlined
        flat
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        v-model="form.welcome_message"
        label="Challenge Welcome Message"
        auto-grow
        outlined
        flat
      />
    </v-col>
  </v-row>
</template>

<script>
import MultipleTrainerSearchInput from '@/components/elements/trainers/MultipleTrainerSearchInput'
import SearchPlanInput from '@/components/elements/plans/SearchPlanInput'
import TrainingProgramSearchInput from '@/components/elements/TrainingProgramSearchInput'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import Form from '@/utils/form'
import dayjs from 'dayjs'
import { map } from 'lodash'
import { mdiCalendar } from '@mdi/js'
import moment from 'moment-timezone'
import { mask } from 'vue-the-mask'

export default {
  name: 'ChallengeDetailsForm',

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  directives: {
    mask,
  },

  components: {
    MultipleTrainerSearchInput,
    SearchPlanInput,
    TrainingProgramSearchInput,
  },

  data() {
    return {
      endDateMenu: false,
      startDateMenu: false,
      accessDateMenu: false,
      form: this.formData,
      validities: [
        { id: 1, name: '3 Months' },
        { id: 2, name: '6 Months' },
        { id: 3, name: '12 Months' },
      ],
      workoutTypes: [
        { id: 1, text: 'Venue' },
        { id: 2, text: 'Training' },
      ],

      icons: {
        calendar: mdiCalendar,
      },
      dateConfig: {
        type: 'string',
        mask: 'iso',
      },
      tzFormat: (date) => {
        // eslint-disable-next-line prettier/prettier
        return date ? moment(date).tz('Australia/Brisbane').format('lll') : null
      },
      swatches: [
        ['#C5D7B1', '#EBA6B7', '#F1D4DF'],
        ['#FFD4A6', '#F6E077', '#96C9DA'],
        ['#000000', '#DBDDDF', '#F6F6F6'],
      ],
    }
  },

  computed: {
    hasDeload() {
      return !!this.form.has_deload
    },
  },

  methods: {
    allowedStartDates(date) {
      dayjs.extend(isSameOrAfter)

      let _dayjs = dayjs.utc(date)
      let _today = dayjs().format('YYYY-MM-DD')

      let _day = Number(dayjs.utc(date).format('d'))
      let _date = _dayjs.format('YYYY-MM-DD')

      let _after = dayjs(_date).isSameOrAfter(dayjs(_today))

      return (_after && _day === 1) || false
    },

    onDateSelect(date = null) {
      let _startDate = this.form.starts_at

      let _date = _startDate || date || null
      let _week = this.form.week_count

      if (!_startDate || !_week) {
        return (this.form.ends_at = null)
      }

      let _endDate = dayjs.utc(_date).add(_week, 'week')

      this.form.ends_at = _endDate.format('YYYY-MM-DD')
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    hasDeload(value) {
      if (!value) {
        this.form.deload_week_count = null
      }
    },

    'form.trainers': {
      handler: (value) => {
        if (this?.form) {
          this.form.trainer_ids = map(value, 'id')
        }
      },
      immediate: true,
    },

    'form.training_programs': {
      handler: (value) => {
        if (this?.form) {
          this.form.training_program_ids = map(value, 'id')
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
input:read-only {
  cursor: default !important;
}
</style>
