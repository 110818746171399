<template>
  <v-combobox
    v-bind="$attrs"
    v-model="selectedTrainingPrograms"
    item-text="name"
    item-value="id"
    :hide-no-data="!search"
    :search-input.sync="search"
    :items="filteredTrainingPrograms"
  >
  </v-combobox>
</template>

<script>
import TrainingProgram from '@/models/TrainingProgram'

export default {
  props: {
    value: {
      type: [Array],
    },
    workoutType: {
      type: Number,
    },
  },
  data() {
    return {
      selectedTrainingPrograms: [],
      trainingPrograms: [],
      search: '',
    }
  },

  async created() {
    const { data } = await TrainingProgram.get()
    this.trainingPrograms = data
    this.selectedTrainingPrograms = this.value
  },
  computed: {
    filteredTrainingPrograms() {
      if (!this.workoutType) return this.trainingPrograms
      return this.trainingPrograms.filter(
        (tp) => tp.workout_type_id === this.workoutType
      )
    },
  },
  watch: {
    value(value) {
      this.selectedTrainingPrograms = value
    },
    selectedTrainingPrograms(value) {
      this.$emit('input', value)
    },
    workoutType(value) {
      this.selectedTrainingPrograms = []
      if (value === 1) {
        this.selectedTrainingPrograms = [...this.filteredTrainingPrograms]
      }
    },
  },
}
</script>
