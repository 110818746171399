<template>
  <v-combobox
    v-bind="$attrs"
    v-model="selectedTrainers"
    item-text="name"
    item-value="id"
    :hide-no-data="!search"
    :search-input.sync="search"
    :items="trainers"
  >
  </v-combobox>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: [Array],
      default() {
        return []
      },
    },
  },
  data() {
    return {
      selectedTrainers: [],
      trainers: [],
      search: '',
    }
  },

  async created() {
    await this.fetchTrainers()
    this.selectedTrainers = this.value
  },
  methods: {
    ...mapActions({
      getTrainers: 'trainers/getTrainers',
    }),
    async fetchTrainers() {
      let trainers = await this.getTrainers({
        page: 1,
        limit: 100,
        toState: false,
      })

      this.trainers = trainers.data
    },
  },
  watch: {
    value(value) {
      this.selectedTrainers = value
    },
    selectedTrainers(value) {
      this.$emit('input', value)
    },
  },
}
</script>
